import { gql } from '@apollo/client';

export default gql`
    query getSubcategoriesByParent($parentCategoryId: Float!) {
        itemSubcategories(filter: {where: {parentSubcategoryId: $parentCategoryId, status: true}, order : ["order asc"]}) {
            id
            name
            order
            status
            category
            parentSubcategoryId
            subCategoryIds
        }
    }
`;
