// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-[restaurant]-authentication-js": () => import("./../../../src/pages/[restaurant]/authentication.js" /* webpackChunkName: "component---src-pages-[restaurant]-authentication-js" */),
  "component---src-pages-[restaurant]-beverage-[id]-js": () => import("./../../../src/pages/[restaurant]/beverage/[id].js" /* webpackChunkName: "component---src-pages-[restaurant]-beverage-[id]-js" */),
  "component---src-pages-[restaurant]-beverage-index-js": () => import("./../../../src/pages/[restaurant]/beverage/index.js" /* webpackChunkName: "component---src-pages-[restaurant]-beverage-index-js" */),
  "component---src-pages-[restaurant]-food-[id]-js": () => import("./../../../src/pages/[restaurant]/food/[id].js" /* webpackChunkName: "component---src-pages-[restaurant]-food-[id]-js" */),
  "component---src-pages-[restaurant]-food-index-js": () => import("./../../../src/pages/[restaurant]/food/index.js" /* webpackChunkName: "component---src-pages-[restaurant]-food-index-js" */),
  "component---src-pages-[restaurant]-index-js": () => import("./../../../src/pages/[restaurant]/index.js" /* webpackChunkName: "component---src-pages-[restaurant]-index-js" */),
  "component---src-pages-[restaurant]-items-js": () => import("./../../../src/pages/[restaurant]/items.js" /* webpackChunkName: "component---src-pages-[restaurant]-items-js" */),
  "component---src-pages-[restaurant]-more-about-us-js": () => import("./../../../src/pages/[restaurant]/more/about-us.js" /* webpackChunkName: "component---src-pages-[restaurant]-more-about-us-js" */),
  "component---src-pages-[restaurant]-more-active-orders-js": () => import("./../../../src/pages/[restaurant]/more/active-orders.js" /* webpackChunkName: "component---src-pages-[restaurant]-more-active-orders-js" */),
  "component---src-pages-[restaurant]-more-conditions-js": () => import("./../../../src/pages/[restaurant]/more/conditions.js" /* webpackChunkName: "component---src-pages-[restaurant]-more-conditions-js" */),
  "component---src-pages-[restaurant]-more-index-js": () => import("./../../../src/pages/[restaurant]/more/index.js" /* webpackChunkName: "component---src-pages-[restaurant]-more-index-js" */),
  "component---src-pages-[restaurant]-more-order-status-js": () => import("./../../../src/pages/[restaurant]/more/order-status.js" /* webpackChunkName: "component---src-pages-[restaurant]-more-order-status-js" */),
  "component---src-pages-[restaurant]-more-receipt-details-js": () => import("./../../../src/pages/[restaurant]/more/receipt-details.js" /* webpackChunkName: "component---src-pages-[restaurant]-more-receipt-details-js" */),
  "component---src-pages-[restaurant]-more-receipts-js": () => import("./../../../src/pages/[restaurant]/more/receipts.js" /* webpackChunkName: "component---src-pages-[restaurant]-more-receipts-js" */),
  "component---src-pages-[restaurant]-more-settings-js": () => import("./../../../src/pages/[restaurant]/more/settings.js" /* webpackChunkName: "component---src-pages-[restaurant]-more-settings-js" */),
  "component---src-pages-[restaurant]-order-js": () => import("./../../../src/pages/[restaurant]/order.js" /* webpackChunkName: "component---src-pages-[restaurant]-order-js" */),
  "component---src-pages-[restaurant]-payment-failed-js": () => import("./../../../src/pages/[restaurant]/payment-failed.js" /* webpackChunkName: "component---src-pages-[restaurant]-payment-failed-js" */),
  "component---src-pages-[restaurant]-redirect-js": () => import("./../../../src/pages/[restaurant]/redirect.js" /* webpackChunkName: "component---src-pages-[restaurant]-redirect-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

