import React, { useEffect, useState } from 'react';
import {
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { bool, func, string, number } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
  label: {
    width: '100%',
  },
}));

const ProductItemDialogCheckbox = ({
  description,
  id,
  handleChange,
  name,
  displayName,
  price,
  checked,
  disabled,
}) => {
  const styles = useStyles();
  const [selected, setSelected] = useState(checked)
  const NameLabel = () => (
    <Typography>{displayName ?? name}</Typography>
  );

  useEffect(() => {
    if (checked) {
      handleChange({
        target: {
          id: id,
          name: displayName ?? name,
          type: 'checkbox',
          checked: true,
          value: id
        }
      })
    }
  }, [])

  return (
    <>
      <FormControlLabel
        classes={{
          label: styles.label,
        }}
        control={
          <Checkbox
            id={id}
            defaultChecked={selected}
            onChange={handleChange}
            name={name}
            color="primary"
            value={price}
            disabled={disabled}
          />
        }
        label={
          <Grid container justifyContent="space-between">
            <Grid item>
              <NameLabel />
            </Grid>
            <Grid item>
              <Typography variant="body2">
                <i>{price > 0 ? `+ ${price} kr` : `0 kr`}</i>
              </Typography>
            </Grid>
          </Grid>
        }
      />
      <Grid>
        <Typography variant="body2" fontSize={'10px'}>
          <i>{description}</i>
        </Typography>
      </Grid>
    </>
  );
};

ProductItemDialogCheckbox.propTypes = {
  id: string.isRequired,
  handleChange: func.isRequired,
  name: string.isRequired,
  displayName: string,
  price: number,
  checked: bool,
  disabled: bool,
  description: string
};

ProductItemDialogCheckbox.defaultProps = {
  checked: false,
  price: 0,
  disabled: false,
};

export default ProductItemDialogCheckbox;
