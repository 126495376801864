import React, { useEffect, useState } from 'react';
// import { Fragment } from 'react';
import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Grid,
} from '@material-ui/core';
import {
  func,
  string,
  number,
  arrayOf,
  shape,
} from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  label: {
    width: '100%',
  },
});

const ProductItemDialogRadio = ({
  handleChange,
  value,
  items,
  name,
  triggerOnChange
}) => {
  const [selected, setSelected] = useState(0)
  useEffect(() => {
    const standardObj = items?.find((item) => item.isStandard === true)
    const preSelected = items?.find((item) => item.checked === true)
    const selectedObj = preSelected ?? standardObj

    if (selectedObj) {
      setSelected(selectedObj.id)
      handleChange({
        target: {
          id: name,
          name: selectedObj.name,
          type: 'radio',
          checked: true,
          value: selectedObj.id
        }
      })
    }
  }, [])

  const styles = useStyles();
  const { t } = useTranslation();

  const handleClick = (event) => {
    setSelected(parseInt(event.target.value));
  }
  return (
    <FormControl
      component="fieldset"
      classes={{
        root: styles.root,
      }}
    >
      <RadioGroup
        name={name}
        aria-label={name}
        value={selected}
        onChange={handleChange}
        onClick={handleClick}
      >
        {items.map(item => {
          return (
            <React.Fragment key={item.id}>
              <FormControlLabel
                key={item.id}
                classes={{
                  label: styles.label,
                }}
                value={item.id}
                name={item.name}
                control={<Radio id={name} color="primary" />}
                label={
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <Typography variant="body2">
                        {item.name}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2">
                        {/* ${t('Currency')} */}
                        <i>
                          {item.price > 0 ? `${item.price} kr` : `0 kr`}
                        </i>
                      </Typography>
                    </Grid>
                  </Grid>
                }
              />
              <Grid>
                <Typography variant="body2" fontSize={'10px'}>
                  <i>{item.description}</i>
                </Typography>
              </Grid>
            </React.Fragment>
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

ProductItemDialogRadio.propTypes = {
  handleChange: func.isRequired,
  name: string,
  items: arrayOf(
    shape({
      name: string,
      price: number,
      id: number,
    })
  ).isRequired,
  value: string, // TODO: Not a string anymore, update accordingly
};

ProductItemDialogRadio.defaultProps = {
  name: '',
  value: '',
};

export default ProductItemDialogRadio;
