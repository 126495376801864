import { gql } from '@apollo/client';

export default gql`
    query getBaseSubcategories($restaurantId: Float!, $category: String!) {
        itemSubcategories(
            filter: {
                where: {
                    restaurantId: $restaurantId, 
                    status: true,
                    category: $category, 
                    parentSubcategoryId: {
                        lt: 0
                    }
                }, order: ["order asc"]
            }
            ) {
            id
            name
            order
            status
            category
            parentSubcategoryId
            subCategoryIds
        }
    }
`;
