export enum Routes {
  NOTFOUND = '/404',
  ABOUT = '/more/about-us',
  ACTIVEORDERS = '/more/active-orders',
  AUTHENTICATION = '/authentication',
  BEVERAGE = '/beverage',
  CONDITIONS = '/more/conditions',
  FOOD = '/food',
  INDEX = '/',
  ITEMS = '/items',
  MORE = '/more',
  ORDERSTATUS = '/more/order-status',
  ORDER = '/order',
  RECEIPTDETAILS = '/more/receipt-details',
  RECEIPTS = '/more/receipts',
  REDIRECT = '/redirect',
  SETTINGS = '/more/settings',
  PAYMENT_FAILED = '/payment-failed',
}
